import './App.css';

function App() {
  return (
    <div className="App">
      <p>Rachel is busy drinking coffee and building this website. Come back soon.</p>
    </div>
  );
}

export default App;
